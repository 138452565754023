import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  distinctUntilChanged,
  filter,
  map,
  startWith,
  Subject,
} from 'rxjs';

export interface EventBusData<Data = unknown> {
  id: string;
  data: Data;
}

@Injectable({ providedIn: 'root' })
export class EventBusService {
  private broadcaster$ = new Subject<EventBusData>();
  private broadcasterRemembered$ = new BehaviorSubject<Record<string, unknown>>(
    {},
  );

  emit<Data>(data: EventBusData<Data>, options?: { remember: boolean }) {
    if (options?.remember) {
      this.broadcasterRemembered$.next({
        ...this.broadcasterRemembered$.value,
        [data.id]: data.data,
      });
    } else {
      this.broadcaster$.next(data);
    }
  }

  on<Data>(id: string, options?: { remember: boolean }) {
    if (options?.remember) {
      return this.broadcasterRemembered$.pipe(
        map((record) => record[id] as Data),
        startWith(this.broadcasterRemembered$.value[id] as Data),
        distinctUntilChanged(),
      );
    } else {
      return this.broadcaster$.asObservable().pipe(
        filter(({ id: eventId }) => id === eventId),
        map(({ data }) => data as Data),
      );
    }
  }
}
